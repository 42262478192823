import { useTranslation } from "react-i18next";
import { CaseForm } from "../global/CaseForm";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { GlobalContext } from "../global/Global";
import { Box, Snackbar, Tab, Tabs } from "@mui/material";
import { getCasesSets } from "../../api/FetchClient";

export default function CompanyData() {
    const { t, i18n } = useTranslation();
    const [ language, setLanguage ] = useState();
    const { company } = useContext(GlobalContext);
    const [ saveSnackbarOpen, setSaveSnackbarOpen ] = useState(false);
    const [ tab, setTab ] = useState(0);
    const [ cases, setCases ] = useState([]);
    const [ caseData, setCaseData ] = useState(null);

    const caseSaved = async (data) => {
        setCaseData(data);
        setSaveSnackbarOpen(true);
    };

    const loadCasesSets = async () => {
        setCases(await getCasesSets(company, 'Company'));
    }

    const switchTab = (event, newValue) => {
        setTab(newValue);
        setCaseData(null);
    };

    useMemo(() => {
        if (i18n.language !== language) {
            setLanguage(i18n.language);
        }

        if (company && cases.length === 0)
            loadCasesSets();
    }, [i18n.language, company]);

    useEffect(() => {
        if (company) {
            setCaseData(null)
        }
        if (company && cases.length === 0)
            loadCasesSets();
    }, [company]);
    
    const TabPanel = (props) => {
        const { children, value, index, ...other } = props;
    
        return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && 
                <Box sx={{ p: 0 }}>{children}</Box>
            }
        </div>
        );
    };

    return (
        company && language ? 
        <>
        <Tabs
            value={tab}
            onChange={switchTab}
            style={{marginTop: 10}}>
                {cases.map(form => 
                    <Tab key={`tab_${form.name}`} label={form.nameLocalizations[language]}></Tab>
                )}
        </Tabs>
        <div className="content">
            {cases.map((form, index) => 
            <TabPanel key={`tab_content_${form.name}`} value={tab} index={index}>
                <CaseForm caseName={form.name} 
                    language={language} 
                    key={form.name} 
                    payrollId={company} 
                    caseSaved={caseSaved} 
                    caseData={caseData}
                    autoReload={false} />
            </TabPanel>
            )}
        </div>
        <Snackbar
            open={saveSnackbarOpen}
            autoHideDuration={3000}
            message={t('Settings saved!')}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            onClose={() => setSaveSnackbarOpen(false)}
        />
        </> : null
    );
}
