import { Button, ButtonGroup } from "@mui/material";
import { useTranslation } from "react-i18next";
import { LS_LANG_KEY } from "../../scenes/global/consts";
import { useEffect } from "react";

export function LanguageSelector({children}) {
    const { i18n } = useTranslation();

    const changeLanguage = (lang) => {
        localStorage.setItem(LS_LANG_KEY, lang);
        i18n.changeLanguage(lang);
    };

    return (
        <ButtonGroup sx={{height: '30px', ml: 2}} aria-label="outlined primary button group">
            <Button onClick={() => changeLanguage('en')} sx={{ color: i18n.language === 'de' ? 'gray' : null }}>EN</Button>
            <Button onClick={() => changeLanguage('de')} sx={{ color: i18n.language === 'en' ? 'gray' : null }}>DE</Button>
        </ButtonGroup>
    );
}
