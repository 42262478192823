import React, { useEffect, useState } from "react";
import { useCreateTheme } from "./theme";
import { CssBaseline, Divider, ThemeProvider, Stack, Grid, Typography, Button, ButtonGroup, Box } from "@mui/material";
import Topbar from "./scenes/global/Topbar";
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { Outlet, useLoaderData } from "react-router-dom";
import { Container } from "@mui/material";
import "dayjs/locale/de";
import "dayjs/locale/en";
import { useTranslation } from "react-i18next";
import { LanguageSelector } from "./components/LanguageSelector";
import { CompanySelector } from "./components/CompanySelector";
import "dayjs/locale/de";
import "dayjs/locale/en";
import { LS_LANG_KEY } from "./scenes/global/consts";
import { Navigation } from "./components/Navigation";
import { Amplify } from 'aws-amplify';
import awsExports from './aws-exports';
import { Authenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import GlobalContextProvider from "./scenes/global/Global";

dayjs.extend(utc);
dayjs.extend(localizedFormat);

export function App() {
	const theme = useCreateTheme();
	const { user } = useLoaderData();
	const { t, i18n } = useTranslation();
	
	useEffect(() => {
		dayjs.locale('de')
		const storedLang = localStorage.getItem(LS_LANG_KEY) || 'en';
		i18n.changeLanguage(storedLang);
		localStorage.setItem(LS_LANG_KEY, storedLang);
	}, [i18n]);

	const containerProps = {px: 0, sm: {px: 0}}

	Amplify.configure({
		Auth: {
			region: awsExports.REGION,
			userPoolId: awsExports.USER_POOL_ID,
			userPoolWebClientId: awsExports.USER_POOL_APP_CLIENT_ID,

			Cognito: {
				userPoolClientId: awsExports.USER_POOL_APP_CLIENT_ID,
				userPoolId: awsExports.USER_POOL_ID,

				loginWith: { // Optional
				  oauth: {
					// domain: 'abcdefghij1234567890-29051e27.auth.us-east-1.amazoncognito.com',
					scopes: ['openid email phone profile aws.cognito.signin.user.admin '],
					redirectSignIn: ['http://localhost:3000/', 'http://ec2-3-120-33-47.eu-central-1.compute.amazonaws.com'],
					redirectSignOut: ['http://localhost:3000/', 'http://ec2-3-120-33-47.eu-central-1.compute.amazonaws.com'],
					responseType: 'code',
				  },
				  username: 'true',
				  email: 'false', // Optional
				  phone: 'false', // Optional
				}
		  
			  }
		}
	})

	return (
		<ThemeProvider theme={theme}>
			<GlobalContextProvider>
				<CssBaseline />
				<Stack className="app" direction="row">
					<Stack sx={{flexGrow: 1}} divider={<Divider />}>
					<Authenticator>
						{({ signOut, user }) => (
						<>
							<Topbar className="topBar">
								<Box sx={{margin: '0 20px', display: 'flex', alignItems: 'center', color: 'var(--primary-color)'}} >
									<img alt="" height="32" src="/salarynow.svg" />
								</Box>

								<Navigation />

								<Grid container justifyContent="flex-end">
									<CompanySelector signOut={signOut} user={user} />
									<LanguageSelector />
									<ButtonGroup sx={{height: '30px', ml: 2}} aria-label="outlined primary button group">
										<Button onClick={signOut}>{t('Sign out')}</Button>
									</ButtonGroup>
								</Grid>
							</Topbar>
							<div id="scroll-container">
								<Container component="main" maxWidth="lg" sx={containerProps}>
									<Outlet />
								</Container>
							</div>
						</>
						)}
					</Authenticator>

					</Stack>
				</Stack>
			</GlobalContextProvider>
		</ThemeProvider>
	);
}
