import { MenuItem, Select } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../../scenes/global/Global";
import { getCompanies } from "../../api/FetchClient";

export function CompanySelector(props) {
    const {company, setCompany, companyData, setCompanyData} = useContext(GlobalContext);
    const [companies, setCompanies] = useState([]);

    useEffect(() => {
        const loadCompanies = async() => {
            if (!props.user.signInDetails) {
                props.signOut();
                return;
            }
            const res = await getCompanies();
            if (res.length > 0) {
                const stored = localStorage.getItem('selectedCompany');
                const index = res.findIndex(cmp => {
                    return cmp.payrollId === parseInt(stored)
                });

                if (stored && index > -1) {
                    setCompany(res[index].payrollId);
                    setCompanyData(res[index]);
                } else {
                    setCompany(res[0].payrollId);
                    setCompanyData(res[0]);
                }

            }
            setCompanies(res);
        };
        loadCompanies();
    }, []);

    const handleChange = (event => {
        setCompany(event.target.value);
        const data = companies.find(cmp => cmp.payrollId === event.target.value);
        localStorage.setItem('selectedCompany', data.payrollId);
        setCompanyData(data)
    });

    return (
        companies.length > 1 ?
        <Select
            sx={{height: '30px', minWidth: '160px'}}
            value={company || null}
            onChange={handleChange}
            >
                {companies.map(company => 
                    <MenuItem key={company._id} value={company.payrollId}>{company.name}</MenuItem>
                )}
        </Select>
        : null
    );
}
