const translations = {
    translation: {
        "Cancel": "Cancel",
        "Save": "Save",
        "Yes": "Yes",
        "No": "No",
        "Employee Data": "Employee Data",
        "Simulation": "Simulation",
        "Home": "Home",
        "Salary Settings": "Salary Settings",
        "Preview": "Preview",
        "Download": "Download",
        "Configuration": "Configuration",
        "specimen of a calculation": "specimen of a calculation",
        "Estimated monthly income in Swiss Francs": "Estimated monthly income in Swiss Francs",
        "Estimation as of": "Estimation as of",
        "Basis of Calculation": "Basis of Calculation",
        "Employer social costs": "Employer social costs",
        "Employee social costs": "Employee social costs",
        "base/amount": "base/amount",
        "rate": "rate",
        "monthly earnings": "monthly earnings",
        "Total Costs": "Total Costs",
        "Salary slip": "Salary slip",
        "Company data" : "Company data",
        "Accident insurance" : "Accident insurance",
        "Daily sickness benefits insurance" : "Daily sickness benefits insurance",
        "Supplementary accident insurance" : "Supplementary accident insurance",
        "Upload Configuration": "Upload Configuration",
        "Clear": "Clear"
    },
};

export default translations;