import { useMemo } from "react";
import { createTheme } from "@mui/material/styles";

const lightModeDesignTokens = {
  palette: {
    mode: "light",
    primary: {
      main: '#461eb7',
      hover: "rgba(70, 30, 183, 0.05)",
      active: "rgba(70, 30, 183, 0.15)",
    },
    text: {
      secondary: "rgba(0, 0, 0, 0.4)"
    }
  }
}

function createThemeSettings(mode) {
  const palette = 'light';

  return {
    ...palette,
    components: {
      MuiStack: {
        defaultProps: {
          useFlexGap: true
        }
      }
    },
    typography: {
      fontSize: 12,
      h1: {
        fontSize: "2.25rem",
      },
      h2: {
        fontSize: "1.625rem",
      },
      h3: {
        fontSize: "1.25rem",
      },
      h4: {
        fontSize: "1rem",
        fontWeight: 'bolder'
      },
      button: {
        textTransform: 'none'
      }
    },
    bgColorFromString: (str) => {
      const hash = getHashCode(str);
      const hue = Math.abs((hash % 90)) * 4;
      const lightness = mode === "light" ? 30 : 90;
      return {
        bgcolor: `hsl(${hue}, 90%, ${lightness - 10}%)`,
        "@supports (color:oklch(0% 0 0))": {
          bgcolor: `oklch(${lightness}%, 0.25, ${hue})`
        }
      };
    }
  };
}

export const useCreateTheme = () => {
  const theme = useMemo(() => createTheme(createThemeSettings('light')), ['light']);
  return theme;
};


function getHashCode(s) {
  let hash = 0;
  for (let i = 0; i < s.length; i += 1) {
      hash = s.charCodeAt(i) + ((hash << 5) - hash);
  }
  return hash;
}