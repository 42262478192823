import { Stack } from "@mui/material";
import { createContext, useRef } from "react";
import { Form, useOutletContext } from "react-router-dom";
import { ContentLayout } from "../../components/ContentLayout";
import { ErrorView } from "../../components/ErrorView";
import { Loading } from "../../components/Loading";
import { CaseFormButtons } from "../../components/buttons/CaseFormButtons";
import { CaseComponent } from "../../components/case/CaseComponent";
import { CaseErrorComponent } from "../../components/case/CaseErrorComponent";
import { EditFieldComponent } from "../../components/case/field/EditFieldComponent";
import { useCaseData } from "../../hooks/useCaseData.js";
export const CaseFormContext = createContext();

export function CaseForm(props) {
  const title = useOutletContext();
  const { caseData, setCaseData, getCaseChangeSetup, caseErrors, fatalError, attachments, loading, buildCase, addCase } = useCaseData({...props});
  const formRef = useRef();
  // const theme = useTheme();
  // this breakpoint was chosen because at this point the datefields + input field
  // don't have enough space
  // const hideIndex = useMediaQuery(theme.breakpoints.down("md"));

  const handleSubmit = () => {
    if (formRef?.current?.reportValidity()) {
      addCase(() => {
        if (props.caseSaved) {
          props.caseSaved(caseData);
        } 
      });
    }
  }

  let content = null;
  if (fatalError) {
    content = <ErrorView error={fatalError} />
  } else if (loading) {
    content = <Loading />;
  } else {
    content = <CaseFormContext.Provider value={{ buildCase, attachments }}>
      <Form method="post" ref={formRef} id="case_form">
      <Stack alignItems="stretch" spacing={4}>
        {caseData && <CaseComponent _case={caseData} FieldRenderComponent={EditFieldComponent} />}
        <CaseErrorComponent errors={caseErrors} />
        <CaseFormButtons onSubmit={handleSubmit} />
      </Stack>    
      </Form>
    </CaseFormContext.Provider>
  }

  return (
    <Stack direction="row" minHeight="100%">
      <ContentLayout title={title} sx={{flex: 1}}>
        {content}
      </ContentLayout>
      {/* { !hideIndex && caseData && <CaseIndexView _case={caseData} /> } */}
    </Stack>
  )
}