
import * as React from "react";

import { createBrowserRouter, defer, matchRoutes, Navigate, redirect } from "react-router-dom";

import { App } from "./App";

import { ErrorView } from "./components/ErrorView.jsx";
import Calculator from "./scenes/Calculator";
import CompanyData from "./scenes/CompanyData/index.js";

const routeData = [
  {
    path: "/",
    element: <App />,
    loader: () => { return { user: null }; },
    ErrorBoundary: ErrorView,
    children: [
      {
        index: true,
        element: <Navigate to="calculator" replace />, 
      },
      {
        path: "calculator",
        element: <Calculator />
      },
      {
        path: "company-data",
        element: <CompanyData />
      },
    ]
  },
];

const browserRouter = createBrowserRouter(routeData,
{
  future: {
    v7_normalizeFormMethod: true
  }
});

export { routeData, browserRouter };