import { generatePath } from 'react-router-dom';
import { getDefaultStore } from 'jotai';
import { langMap } from '../services/converters/LanguageConverter';
import { LS_LANG_KEY } from '../scenes/global/consts';
import { fetchAuthSession } from '@aws-amplify/auth';

// const baseUrl = 'http://localhost:4000/v1';
const baseUrl = 'https://app.salarynow.ch/api/v1';

const companiesByEmail    = "/company/byEmail";
const casesSetsUrl        = "/company/cases/:payrollId/:caseType";
const templatesUrl        = "/template/list";
const calculateUrl        = "/form/payrolls/calculate/:payrollId";
const payrunResultUrl     = "/form/payrunResults/:payrunJobId";
const payrollUrl          = "/form/payrolls/:payrollId";
const caseSetsUrl         = "/form/payrolls/:payrollId/cases/sets";
const lookupValuesUrl     = "/form/payrolls/:payrollId/lookups/values";
const caseFieldsUrl       = "/form/payrolls/:payrollId/casefields";
const usersUrl            = "/form/users";
const employeeDocumentUrl = "/form/employees/:employeeId/cases/:caseValueId/documents/:documentId";
const companyDocumentUrl  = "/form/companycases/:caseValueId/documents/:documentId";
const wageTypesUrl        = "/form/payrolls/wagetypes/:payrollId";

class FetchRequestBuilder {
    method = "GET";
    headers = new Headers();
    searchParams = new URLSearchParams();
    signal = AbortSignal.timeout(60000);
    localizeRequest = false;
    body = null;
    url = null;
    routeParams = {};
    addUserQueryParam = false;

    constructor(url, routeParams) {
        if (!url) {
            throw new Error("Url cannot be empty");
        }
        this.url = baseUrl + generatePath(url, routeParams);
        this.routeParams = routeParams || {};
        this.headers.set('Accept', 'application/json');
        this.headers.set('Content-Type', 'application/json');
    }

    async withToken() {
        try {
          const session = await fetchAuthSession();
        //   console.log('Access Token:', session.tokens.accessToken.toString());
        //   console.log('ID Token:', session.tokens.idToken.toString());
          this.headers.set('Authorization', `Bearer ${session.tokens.idToken.toString()}`);
        }
        catch (e) { console.log(e); }
    };

    withRouteParams(routeParams) {
        this.routeParams = routeParams;
        return this;
    }

    withMethod(method) {
        this.method = method;
        return this;
    }

    withLocalization() {
        this.localizeRequest = true;
        return this;
    }

    withBody(body) {
        if(body) {
            this.body = JSON.stringify(body);
        }
        return this;
    }

    withQueryParam(key, value) {
        if(value) {
            this.searchParams.append(key, value);
        }
        return this;
    }

    withTimout(timeout) {
        this.signal = AbortSignal.timeout(timeout);
        return this;
    }

    withUser() {
        this.addUserQueryParam = true;
        return this;
    }

    async fetch(skipLang = false) {
        const language = localStorage.getItem(LS_LANG_KEY);
        if (language && !skipLang)
            this.searchParams.append("language", langMap[language] || 'German');
        let url = this.url;
        if ([...this.searchParams].length > 0) {
            url = `${url}?${this.searchParams}`;
        }

        await this.withToken();

        return fetch(url, {
            method: this.method,
            headers: this.headers,
            body: this.body,
            signal: this.signal
        });
    }

    async fetchJson(skipLang = false) {
        return (await this.fetch(skipLang)).json();
    }

}

export function buildCase(routeParams, caseChangeSetup) {
    // manually construct path, generatePath does not handle encoding properly
    // const url = `${caseSetsUrl}/${encodeURIComponent(routeParams.caseName)}`;
    const url = `${caseSetsUrl}/${encodeURIComponent(routeParams.caseName)}${routeParams.forecast ? '/' + routeParams.forecast : ''}`;
    return new FetchRequestBuilder(url, routeParams)
        .withMethod("POST")
        .withBody(caseChangeSetup)
        .withLocalization()
        .withUser()
        .fetch();
}

export function addCase(routeParams, caseChangeSetup) {
    caseChangeSetup.forecast = routeParams.forecast;
    return new FetchRequestBuilder(caseSetsUrl, routeParams)
        .withMethod("POST")
        .withBody(caseChangeSetup)
        .withLocalization()
        .withUser()
        .fetch();
}

export async function getUser(routeParams, identifier) {
    const users = await new FetchRequestBuilder(usersUrl, routeParams)
        .withQueryParam("filter", `Identifier eq '${identifier}'`)
        .fetchJson();
    const user = users?.length ? users[0] : null;
    return user;
}

export function getLookupValues(routeParams, lookupName) {
    return new FetchRequestBuilder(lookupValuesUrl, routeParams)
        .withQueryParam("lookupNames", lookupName)
        .withLocalization()
        .fetchJson();
}

export function getLookupValuesWithLangParam(routeParams, lookupName, lang) {
    return new FetchRequestBuilder(lookupValuesUrl, routeParams)
        .withQueryParam("lookupNames", lookupName)
        .withQueryParam("language", lang)
        .fetchJson(true);
}

export function getDocumentCaseFields(routeParams) {
    const caseType = !!routeParams.employeeId ? "Employee" : "Company";
    return new FetchRequestBuilder(caseFieldsUrl, routeParams)
        .withQueryParam("caseType", caseType)
        .withQueryParam("valueType", "Document")
        .withLocalization()
        .fetchJson();
}

export function getWageTypes(routeParams) {
    return new FetchRequestBuilder(wageTypesUrl, routeParams)
        .withLocalization()
        .fetchJson();
}

export function getDocumentsOfCaseField(routeParams, caseFieldName, top) {
    const url = payrollUrl + "/changes/values";
    const caseType = !!routeParams.employeeId ? "Employee" : "Company";
    return new FetchRequestBuilder(url, routeParams)
        .withQueryParam("employeeId", routeParams.employeeId)
        .withQueryParam("caseType", caseType)
        .withQueryParam("orderBy", "start desc")
        .withQueryParam("result", "ItemsWithCount")
        .withQueryParam("top", top)
        .withQueryParam("filter", `CaseFieldName eq '${caseFieldName}'`)
        .withLocalization()
        .withUser()
        .fetchJson();
}

export function getDocument(routeParams) {
    const url = routeParams.employeeId ? 
        employeeDocumentUrl :
        companyDocumentUrl;

    return new FetchRequestBuilder(url, routeParams).fetchJson();
}

export function calculate(params) {
    const url = calculateUrl
    return new FetchRequestBuilder(url, params)
        .withMethod("POST")
        .withBody(params)
        .withLocalization()
        .withUser()
        .fetchJson();
}

export function getPayrunResult(payrunJobId) {
    const url = payrunResultUrl
    return new FetchRequestBuilder(url, { payrunJobId: payrunJobId })
        .withMethod("GET")
        .withLocalization()
        .withUser()
        .fetchJson();
}

export function getCompanies() {
    return new FetchRequestBuilder(companiesByEmail)
        .withMethod("GET")
        .fetchJson();
}

export function getCasesSets(payrollId, caseType) {
    return new FetchRequestBuilder(casesSetsUrl, { payrollId, caseType })
        .withLocalization()
        .withUser()
        .fetchJson();
}

export function getTemplates() {
    return new FetchRequestBuilder(templatesUrl)
    .fetchJson();
}
