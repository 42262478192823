import { useRef, useState, useEffect } from 'react';
import { buildCase, addCase } from '../api/FetchClient';


function mapCase(_case, attachments) {
  return {
    caseName: _case.name,
    values: _case.fields.map(f => ({
      caseName: _case.name,
      caseFieldName: f.name,
      value: f.value,
      start: f.start,
      end: f.end,
      documents: attachments[f.id]
    })),
    relatedCases: _case.relatedCases.map(c => mapCase(c, attachments))
  };
}

export function useCaseData(params) {
    const attachments = useRef({});
    const [caseData, setCaseData] = useState(params.caseData);
    const [caseErrors, setCaseErrors] = useState([]);
    const [loading, setLoading] = useState(true);
    const [fatalError, setFatalError] = useState(null);

    useEffect(() => {
      const loadData = async () => {
        setLoading(true);
        await _buildCase();
        setLoading(false);
      };
      if (params.autoReload || !caseData) {
        loadData();
      } else {
        setLoading(false);
      }
    }, [params.language, params.payrollId, params.forecast, params.autoReload]);

    function getCaseChangeSetup() {
      if (!caseData) {
        return null;
      }
      const caseChangeSetup = {
        case: mapCase(caseData, attachments)
      }
      if (params.employeeId) {
        caseChangeSetup.employeeId = Number(params.employeeId);
      }
      return caseChangeSetup;
    }

    async function handleError(caseResponse) {
      const response = await caseResponse.json();
      if (caseResponse.status >= 400 && caseResponse.status < 500) {
        if (typeof response === "string") {
          setCaseErrors([response]);
        } else {
          const errors = Object
            .entries(response.errors)
            .flatMap(entry => entry[1]
              .flatMap(e => `${entry[0]}: ${e}`));
          setCaseErrors(errors);
        }
      }
      else {
        setFatalError(new Error(response))
      }
    }

    async function _buildCase() {
      const caseResponse = await buildCase(params, getCaseChangeSetup());
      if (caseResponse.ok) {
        setCaseData(await caseResponse.json());
        setCaseErrors([]);
        setFatalError(false);
      } else {
        handleError(caseResponse);
      }
    }

    async function _addCase(onCaseAdded) {
      const caseResponse = await addCase(params, getCaseChangeSetup());
      if (caseResponse.ok) {
        const newCase = await caseResponse.json();
        onCaseAdded(newCase);
        return;
      }
      else {
        handleError(caseResponse);
      }
    }

    return { caseData, setCaseData, getCaseChangeSetup, caseErrors, fatalError, attachments, loading, buildCase: _buildCase, addCase: _addCase };
}