import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Box, Button, CircularProgress, Tab, Tabs } from "@mui/material";
// import { DropzoneArea } from "mui-file-dropzone";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import * as uuid from "uuid";
import { calculate, getPayrunResult, getWageTypes } from "../../api/FetchClient";
import { CaseForm } from "../global/CaseForm";
import { GlobalContext } from "../global/Global";
import { CALCULATOR_PERSONAL_ID } from "../global/consts";
import ResultTabContent from "./ResultTabContent";
import { getEntryDate } from '../../services/helpers/calculation.helper';
import ClearIcon from '@mui/icons-material/Clear';

export default function Calculator() {
    const { t, i18n } = useTranslation();
    const [ language, setLanguage ] = useState();
    const { company, caseData, setCaseData } = useContext(GlobalContext);
    const [ calculationPending, setCalculationPending ] = useState(false);
    const [ jobId, setJobId ] = useState(null);
    const [ calcResult, setCalcResult ] = useState(null);
    const [ tab, setTab ] = useState(0);
    const [ formData, setFormData ] = useState(null);
    const [ wageTypes, setWageTypes ] = useState([]);
    const [ forecast, setForecast ] = useState(uuid.v4());
    const confInputRef = useRef(null);

    const switchTab = (event, newValue) => {
        setTab(newValue);
    };

    useEffect(() => {
        if (i18n.language !== language) {
            setLanguage(i18n.language);
        }
    }, [i18n.language, company]);

    const caseSaved = async (caseData) => {
        setCaseData(caseData);

        getWageTypes({payrollId: company}).then(wt => {
            setWageTypes(wt);
        });

        setCalculationPending(true);
        // temp fix for api issue
        setTimeout(async () => {
            setTab(1);
            const res = await calculate({payrollId: company, forecast, entryDate: getEntryDate(caseData)});
            setJobId(res.id);
            setFormData(formData);
            const intVal = setInterval(async () => {
                const calcResult = await getPayrunResult(res.id);
                if (calcResult.status !== 'pending') {
                    clearInterval(intVal);
                    setCalculationPending(false);
                    setCalcResult(calcResult);
                }
            }, 1000);
    
        }, 1000);
    };

    const TabPanel = (props) => {
        const { children, value, index, ...other } = props;
    
        return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
            <Box sx={{ p: 0 }}>{children}</Box>
            )}
        </div>
        );
    };

    const fileDropped = (files) => {
        try {
            if (files.length > 0) {
                const reader = new FileReader();
                reader.onload = async (evt) => {
                    const parsed = JSON.parse(evt.target.result);
                    if (parsed && parsed.displayName && parsed.fields) {
                        setCaseData(parsed);
                    }
                };
                reader.readAsText(files[0]);
            }
        }

        catch(err) {}
    };

    const clearForm = () => {
        setForecast(uuid.v4());
        setCaseData(null);
    };

    return (
        <>
            <Tabs
                value={tab}
                onChange={switchTab}
                style={{marginTop: 10}}>
                    <Tab label={t('Employee Data')}></Tab>
                    <Tab disabled={!calcResult} label={t('Preview')} />
                    {/* <Tab disabled={!calcResult} label={t('Download')} /> */}
            </Tabs>

            <TabPanel value={tab} index={0}>


                {company && language && !calculationPending ? 
                    <div className="content" style={{position: 'relative'}}>

                    <input onChange={event => fileDropped(event.target.files)} ref={confInputRef} accept="application/json" multiple={false} type="file" style={{display: 'none'}} />
                    <Button onClick={() => confInputRef.current.click()} sx={{position: 'absolute', right: '0', marginTop: '-70px'}} variant='outlined'  startIcon={<CloudUploadIcon />}>
                        {t('Upload Configuration')}
                    </Button>

                    <Button onClick={() => clearForm()} sx={{position: 'absolute', right: '200px', marginTop: '-70px'}} variant='outlined'  startIcon={<ClearIcon />}>
                        {t('Clear')}
                    </Button>

                    {/* <Box class="dropzone-custom" style={{marginTop: '40px'}}>
                        <DropzoneArea sx={{ minHeight: '50px' }} 
                                        onChange={(files) => fileDropped(files)} 
                                        acceptedFiles={["application/json"]}
                                        showPreviews={false}
                                        filesLimit={1} />
                    </Box> */}
                    
                    <CaseForm caseName={CALCULATOR_PERSONAL_ID} 
                        language={language} 
                        key={CALCULATOR_PERSONAL_ID} 
                        caseSaved={caseSaved} 
                        payrollId={company} 
                        forecast={forecast}
                        caseData={caseData}
                        autoReload={true} /> 
                    </div>: null}
            </TabPanel>

            <TabPanel value={tab} index={1}>
                {calculationPending ? 
                <div class="content" style={{ margin: 2, paddingLeft: '100px', paddingBottom: '50px' }}>
                    Calculating...
                    <CircularProgress style={{ marginLeft: '40px', position: 'relative', top: '16px'}} />
                </div> : 
                <ResultTabContent 
                    wageTypes={wageTypes} 
                    jobId={jobId} 
                    result={calcResult} 
                    caseData={caseData} 
                    formData={formData}
                />}
            </TabPanel>

            {/* <TabPanel value={tab} index={2}>
                <div className="content">
                    <DownloadTabContent formData={formData} />
                </div>
            </TabPanel> */}
        </>

    );
}
