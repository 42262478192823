import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import { GlobalContext } from "../../scenes/global/Global";

export function Navigation(props) {
    const { t } = useTranslation();
    const { company } = useContext(GlobalContext);
    const location = useLocation();
    const locationMatch = str => location.pathname.includes(str);

    return (
        <>
            <Link component="button" to="/calculator" underline="none">
                <Button variant='text' className={locationMatch('calculator') ? 'nav-button active': 'nav-button'}>{t('Home')}</Button>
            </Link>

            <Link component="button" to="/company-data" underline="none">
                <Button variant='text' className={locationMatch('company-data') ? 'nav-button active': 'nav-button'} sx={{minWidth: '120px'}}>{t('Salary Settings')}</Button>
            </Link>
        </>
    );
}
