const translations = {
    translation: {
        "Cancel": "Abbrechen",
        "Save": "Speichern",
        "Yes": "Ja",
        "No": "Nein",
        "Employee Data": "Mitarbeiterdaten",
        "Simulation": "Simulation",
        "Home": "Startseite",
        "Salary Settings": "Unternehmenskonfigurationen",
        "Preview": "Vorschau",
        "Download": "Herunterladen",
        "Configuration": "die Konfigurierung",
        "specimen of a calculation": "Musterkalkulation",
        "Estimated monthly income in Swiss Francs": "Geschätztes Einkommen in Schweizer Franken",
        "Estimation as of": "Schätzung vom",
        "Basis of Calculation": "Basisdaten",
        "Employer social costs": "Arbeitgeberbeiträge",
        "Employee social costs": "Arbeitnehmerbeiträge",
        "base/amount": "Basis/Menge",
        "rate": "Ansatz",
        "monthly earnings": "Monatseinkommen",
        "Total Costs": "Total Kosten",
        "Salary slip": "Lohnabrechnung",
        "Company data" : "Unternehmensdaten",
        "Accident insurance" : "Unfallversicherung",
        "Daily sickness benefits insurance" : "Krankentaggeldversicherung",
        "Supplementary accident insurance" : "Unfallzusatzversicherung",
        "Upload Configuration": "Konfiguration hochladen",
        "Clear": "Klar"
    },
};

export default translations;