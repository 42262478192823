import React, { createContext, useState } from 'react'
export const GlobalContext = createContext()

const GlobalContextProvider = (props) => {
    const [ company, setCompany ] = useState();
    const [ companyData, setCompanyData ] = useState(null);
    const [ caseData, setCaseData ] = useState(null);

    return (
         <GlobalContext.Provider 
            value={{
                company,
                setCompany,
                companyData,
                setCompanyData,
                caseData, 
                setCaseData
             }}>
               {props.children}
         </GlobalContext.Provider>
    )
}
export default GlobalContextProvider;