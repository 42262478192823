import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { GlobalContext } from "../global/Global";
import { Box, Button, MenuItem, Select } from "@mui/material";
import { getLookupValuesWithLangParam, getTemplates } from "../../api/FetchClient";
import { formatValue, getEmployeeFirstLastName, processCalculation, processFooterFields, processHeaderFields, processMetaFields } from "../../services/helpers/calculation.helper";
import { langMap } from "../../services/converters/LanguageConverter";
import jsPDF from 'jspdf';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';

export default function ResultTabContent(props) {
    const { t, i18n } = useTranslation();
    const [ lang, setLang ] = useState('de');
    const { companyData } = useContext(GlobalContext);
    const [ templates, setTemplates ] = useState([]);
    const [ template, setTemplate ] = useState(null);
    const [ sections, setSections ] = useState([]);
    const [ header, setHeader ] = useState([]);
    const [ footer, setFooter ] = useState([]);
    const [ meta, setMeta ] = useState([]);
    const [ internalTranslations, setInternalTranslations ] = useState({});
    const [ debugMode, setDebugMode ] = useState(false);
    const languages = ['de', 'en'];
    const changeTemplate = (event) => {
        setTemplate(event.target.value);
        setSections(processCalculation(
                props.result.data, 
                event.target.value, 
                props.wageTypes
            ).sections
        );
        setExtraFields(event.target.value, props.caseData, companyData, t);
    };

    const setExtraFields = async(tpl, caseData, companyData, t) => {
        const extraFields = processMetaFields(tpl, caseData, companyData, t);
        const promises = [];
        extraFields.forEach(async extra => {
            if (extra.lookupSettings) {
                const promise = getLookupValuesWithLangParam({
                    payrollId: companyData.payrollId
                }, extra.lookupSettings.lookupName, langMap[lang])
                promises.push(promise);
                promise.then(lookupData => {
                    const lookupValues = lookupData[0].values.map(lv => JSON.parse(lv.value));
                    const found = lookupValues.find(val => {
                        return val[extra.lookupSettings.valueFieldName] === extra.value
                    });
                    if (found) {
                        extra.value = found[extra.lookupSettings.textFieldName];
                    } else {
                        extra.value = null;
                    }
                })
            }
        });
        
        Promise.all(promises).then(() => {
            setMeta(extraFields);
        });

        setHeader(processHeaderFields(tpl, companyData));
        setFooter(processFooterFields(tpl, companyData));
    };

    const downloadPDF = () => {
        const { firstName, lastName } = getEmployeeFirstLastName(props.caseData);
        const doc = new jsPDF('p', 'pt', 'a2');
        doc.setFont('Helvetica', 'normal');
        doc.html(
            document.querySelector('#report'), {
                left: 220,
                top: 80,
                'width': 522
            }
        ).then(() => {
            const filename = `${firstName} ${lastName} - ${template.name} - DR_${props.result.data.payrunJobId}.pdf`;
            doc.save(filename);
        });
    };

    const downloadConfig = () => {
        const { firstName, lastName } = getEmployeeFirstLastName(props.caseData);
        const name = `${firstName} ${lastName} - DR_${props.result.data.payrunJobId}.json`;
        const a = document.createElement('a');
        const type = name.split(".").pop();
        a.href = URL.createObjectURL( new Blob([JSON.stringify(props.caseData)], { type:`text/${type === "txt" ? "plain" : type}` }) );
        a.download = name;
        a.click();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
        getTemplates().then(tpls => {
            setTemplates(tpls);
            if (!template) {
                setTemplate(template ?? tpls[0]);
                setSections(processCalculation(props.result.data, tpls[0], props.wageTypes).sections);
                setExtraFields(tpls[0], props.caseData, companyData, t);
            } else {
                setExtraFields(template, props.caseData, companyData, t);
            }
        });
        
        setInternalTranslations(i18n.options.resources[lang].translation);
    }, [lang]);

    return <>
        <Box sx={{p: '24px 0 0 16px', display: 'flex', alignItems: 'center', gap: 1}}>
            {t('Language')}
            <Select
                sx={{height: '30px', minWidth: 0.1}}
                value={lang || null}
                onChange={(event) => setLang(event.target.value)}
                >
                    {languages.map(lng => 
                        <MenuItem key={lng} value={lng}>{lng}</MenuItem>
                    )}
            </Select>

            {t('Template')}
            <Select
                sx={{height: '30px', minWidth: 0.12}}
                onChange={changeTemplate}
                value={template ? template : null}
                >
                    {templates.map(tpl => 
                        <MenuItem key={tpl._id} value={tpl}>{t(tpl.name)}</MenuItem>
                    )}
            </Select>

            <Box sx={{marginLeft: 'auto', display: 'flex', gap: '16px'}} >
                <Button onClick={() => setDebugMode(!debugMode)}>Debug</Button>
                <Button variant='outlined' onClick={downloadConfig} startIcon={<CloudDownloadIcon />}>
                    {t('Configuration')}
                </Button>
                <Button variant='contained' onClick={downloadPDF} startIcon={<PictureAsPdfIcon />}>{t('Download PDF')}</Button>
            </Box>
        </Box>
        
        <div className="content">
        <div id="report" style={{
                display: 'flex',
                flexWrap: 'wrap',
                fontFamily: "Helvetica"
            }}>

            <div style={{
                display: 'flex',
                width: '100%',
                flexWrap: 'wrap',
                padding: '16px 36px'
            }}>
                <div style={{ width: '100%' }}>
                    <div style={{position: 'absolute', lineHeight: '26px'}}>{t('Version')}: DR_{props.result.data.payrunJobId}</div>
                    {header.map(row => (
                        <div style={{padding: '3px 0', ...row.display}}>
                            {row.value}
                        </div>
                    ))} 
                </div>
                <div style={{width: '80%', display: 'flex', 'flexWrap': 'wrap'}}>
                    {meta.map(row => (
                        <div style={{padding: '3px 0', ...row.display, display: 'flex', 'flexWrap': 'wrap'}}>
                            {row.type === 'fixed' ? <>
                                {row.nameLocalizations ?
                                    <div style={{width: '50%'}}>{row.nameLocalizations[lang] ?? ''}</div>
                                    : null}
                                    <div style={{width: '50%'}}>{formatValue(row.value, row.valueType, row.negative)}</div>
                                </>
                            : internalTranslations[row.value] ?? row.value}
                        </div>
                    ))}
                </div>

                <div style={{width: '20%', padding: '20px'}}>
                    <img alt="" width="200" src={companyData.logo} />
                </div>
            </div>
            
            {sections && sections.map(section => 
                <table className="result-table" style={{...section.display}}>
                    <thead>
                        {section.title ? 
                            <tr>
                                <th style={{textAlign: 'center'}} colSpan={4}>{internalTranslations[section.title]}</th>
                            </tr>
                        : null}
                        <tr>
                        {section.columns.map(col => 
                            <th>{internalTranslations[col]}</th>
                        )}
                        </tr>
                    </thead>
                    <tbody>
                        {section.fields.map((row, i) => 
                            row.type === 'separator' ?
                            <tr style={{height: '24px'}}>
                                <td colspan="4"></td>
                            </tr>
                            :
                            <tr className={row.styles ? row.styles.join(' ') : ''} key={`row_${i}`} style={{...row.display}}>
                                <td>
                                    {debugMode ? <small style={{position: 'relative', color: 'red', marginRight: '2px', marginTop: '2px'}}>{ row.wageTypeNumber }</small> : null}
                                    { row.wageTypeNameLocalizations[lang] }</td>
                                {section.attributes.map(attr => 
                                    <td>{attr.map(sAttr => formatValue(row.attributes[sAttr], sAttr))}</td>
                                )}
                                <td>{ formatValue(row.value, row.valueType, row.negative) }</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            )}
            <div style={{ width: '100%' }}>
                {footer.map(row => (
                    <div style={{padding: '3px 0', ...row.display}}>
                        {row.value}
                    </div>
                ))} 
            </div>
        </div>
    </div>
    </>
}
