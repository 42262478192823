import { EmailTwoTone } from "@mui/icons-material";

export const processCalculation = (
      payrunResult, 
      template, 
      wageTypesList
    ) => {
    const result = {
      sections: []
    };

    template.sections.forEach((tplSection) => {
      const section = {
        fields: [],
        attributes: tplSection.attributes,
        columns: tplSection.columns,
        display: tplSection.display,
        title: tplSection.title
      };

      tplSection.fields.forEach((tplField) => {
        switch (tplField.type) {
          case 'fixed':
            const found = payrunResult.wageTypeResults.find(
              (row) => row.wageTypeNumber === tplField.id && row.value > 0
            )
            if (found) {
              const cp = Object.assign({}, found);
              cp.styles = tplField.styles;
              cp.display = tplField.display;
              cp.negative = tplField.negative;
              section.fields.push(cp);
            }
            break;

          case 'dynamic':
            wageTypesList
              .filter((wtl) => {
                return wtl.collectors?.includes(tplField.collectorName)
              })
              .sort((a, b) => a.wageTypeNumber - b.wageTypeNumber)
              .forEach((element) => {
                const found = payrunResult.wageTypeResults.find((wt) => {
                  return (
                    wt.wageTypeNumber === element.wageTypeNumber &&  wt.value > 0
                  )
                })
                if (found) section.fields.push({...tplField, ...found});
              })
            break;

            case 'separator':
                section.fields.push(tplField);
            break;
            default:
        }
      })
      result.sections.push(section);
    })
    


    return result;
  }

  export const processMetaFields = (
    template, 
    caseData,
    companyData,
    translator
  ) => {
    const result = [];
    template.meta.forEach(tplMeta => {
      // eslint-disable-next-line default-case
      let meta = {...tplMeta};
      switch (meta.type) {
        case 'companyData':
          meta.value = companyData[tplMeta.value];
        break;

        case 'translation':
          meta.value = translator(tplMeta.value)
        break;

        case 'currentDate':
          meta.value = formatDate(new Date());
        break;

        case 'fixed':
          if (Array.isArray(tplMeta.value)) {
            let value = '';
            tplMeta.value.forEach(valItem => {
              // caseData.relatedCases.forEach(relatedCase => {
                const found = caseData.fields.find(val => val.name === valItem);
                if (found) {
                  value += ` ${found.value}`;
                }
              // });

              meta = {
                ...meta,
                value
              };
            });
          } else {
          // caseData.relatedCases.forEach(relatedCase => {
            const found = caseData.fields.find(val => val.name === meta.value);
            if (found) {
              meta = {
                ...meta,
                ...found
              };
            }
          // });
        }

        break;
        default:
      }

      if (meta.value !== '' && (meta.value && !meta.value.toString().startsWith('CH.Swissdec.')))
        result.push(meta);
    });


    return result;
  }

  export const processHeaderFields = (
    template, 
    companyData
  ) => {
    const result = [];

    if (template.header) {
      template.header.forEach(tplHeader => {
        let header = {...tplHeader};
        header.value = companyData[tplHeader.value];
        result.push(header);
      });
    }

    return result;
  }

  export const processFooterFields = (
    template, 
    companyData,
  ) => {
    const result = [];

    if (template.footer) {
      template.footer.forEach(tplFooter => {
        let footer = {...tplFooter};
        footer.value = companyData[tplFooter.value];
        result.push(footer);
      });
    }

    return result;
  }

  export const formatDate = (date) => {
    const d = date.getDate(), m = date.getMonth() + 1, y = date.getFullYear();

    const dateStr = `${d > 9 ? d : `0${d}`}.${m > 9 ? m : `0${m}`}.${y}`;
    return dateStr;
  };

  export const formatValue = (value, type, negative = false) => {
    switch(type) {
        case 'Percent':
        case 'Percentage':
          let percent = (parseFloat(value) * 100);
          
          if (!percent) return '';
          percent = parseFloat(percent.toFixed(4));
          return percent + '%';
        case 'Date':
            return formatDate(new Date(value));
        case 'Money':
            return new Intl.NumberFormat("de-CH", { style: 'currency', currency: 'CHF' }).format(negative ? value * -1 : value).replace('CHF', 'CHF ');
        case 'Amount':
        case 'Factor':
            return value ? new Intl.NumberFormat("de-CH", { style: 'currency', currency: 'CHF' }).format(negative ? value * -1 : value).replace('CHF', '') : '';
        default:
            return value;
    }
};

export const getEmployeeFirstLastName = (caseData) => {
  const firstName = caseData.fields.find(val => val.name === 'CH.SalaryCalculator.EmployeeFirstName');
  const lastName = caseData.fields.find(val => val.name === 'CH.SalaryCalculator.EmployeeLastName');
  return { firstName: firstName.value, lastName: lastName.value };
};

export const getEntryDate = (caseData) => {
  const entryDateField = caseData.fields.find(val => val.name === 'CH.Swissdec.EmployeeEntryDate');
  return entryDateField ? entryDateField.value : null;
};