import {
  Box,
  IconButton,
  AppBar,
  Toolbar,
} from "@mui/material";

function Topbar({ children }) {

  return (
    <AppBar elevation={0} sx={{ backgroundColor: "background.default", position: "static" }}>
      <Toolbar disableGutters sx={{px: 2, gap: 2}} >
        {children}
        <Box sx={{flexGrow: 1}} />
      </Toolbar>
    </AppBar>
  );
};

export default Topbar;
